import {
	deleteCategory,
	deleteLink,
	fetchCategories,
	fetchLinks,
	fetchLinksByCategory,
	updateCategory,
	updateLink,
	uploadDefaultImage,
	uploadNewImage
} from "./firebaseTools";

const isImageValid = (image) => {
	return SUPPORTED_IMAGE_FORMATS.includes(image.type) ||
		(typeof image === 'string' && image.startsWith('https://firebasestorage.googleapis.com'));
}

const sortLinks = (links) => {
	return links.sort((a, b) => a.name.localeCompare(b.name));
};

export const MAX_CATEGORIES = 5;
export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png'];
export const FOOTER_URL_LINK = "https://portfolio.luctatu.fr/";
export const FOOTER_TITLE = "Actibus immensis urbs fulget Massiliensis";
export const PAGE_TITLE = "My Work Space";

export const calculateNextIdx = (categories) => {
	const maxIdx = categories
		.filter(cat => cat.category !== "")
		.reduce((max, cat) => Math.max(max, cat.idx), 0);

	return maxIdx + 1;
};

export const getFormattedValue = (value) => {
	return value
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const padCategories = (categories) => {
	const categoriesToDisplay = [...categories];
	const totalCategories = categoriesToDisplay.length;
	const remainder = totalCategories % MAX_CATEGORIES;

	if (remainder !== 0) {
		const emptyCategoriesCount = MAX_CATEGORIES - remainder;
		for (let i = 0; i < emptyCategoriesCount; i++) {
			categoriesToDisplay.push({category: "", idx: 10000 + i});
		}
	}

	return categoriesToDisplay;
};

export const sortCategories = (categories) => {
	return categories
		.filter(category => category !== null)
		.sort((a, b) => a.idx - b.idx);
};

export const generateUniqueLinkName = (links, name, category, isMergeCategory = false, isMigration = false) => {
	const existingLinks = links.filter(link =>
		link.category === category && link.name.replace(/ \(\d+\)$/, '') === name
	);

	if (!isMergeCategory) {
		if (isMigration) {
			return name;
		} else {
			return existingLinks.length > 0 ? `${name} (${existingLinks.length + 1})` : name;
		}
	} else {
		return existingLinks.length > 0 ? `${name} (${existingLinks.length + 1})` : name;
	}
};

export const validateFormAddLink = (formData) => {
	const {category, name, url, image} = formData;
	if (!category || !name || !url) {
		alert('All fields are required.');
		return false;
	}
	if (image && !SUPPORTED_IMAGE_FORMATS.includes(image.type)) {
		alert('Unsupported image format. Please upload a JPEG or PNG.');
		return false;
	}
	return true;
};

export const getLinksByCategories = (categoriesToDisplay, links) => {
	return categoriesToDisplay.reduce((acc, categoryObj) => {
		const {category} = categoryObj;
		acc[category] = sortLinks(links.filter(link => link.category === category));
		return acc;
	}, {});
}

export const uploadImageToFirebase = async (image, setError) => {
	if (image) {
		if (!isImageValid(image)) {
			alert('Unsupported image format. Please upload a JPEG or PNG.');
		}

		if (typeof image !== 'string') {
			return await uploadNewImage(image, setError);
		} else {
			return image;
		}
	} else {
		return await uploadDefaultImage(setError);
	}
}

export let removeCategoryFromFirebase = async (categoryId, setError) => {
	if (categoryId === null) {
		setError('Invalid category ID for removal');
		return false;
	}

	return await deleteCategory(categoryId, setError);
};

export const removeLinkFromFirebase = async (linkId, setError) => {
	if (linkId === null) {
		setError('Invalid link ID for removal');
		return false;
	}
	return await deleteLink(linkId, setError);
};

export const getCategories = async (setCategories, setError) => {
	const querySnapshot = await fetchCategories(setError);
	if (querySnapshot?.docs) {
		const mapCategoriesResponse = querySnapshot.docs
			.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

		const sortedCategories = sortCategories(mapCategoriesResponse);
		setCategories(sortedCategories);
	}
};

export const getLinks = async (setLinks, setError) => {
	const querySnapshot = await fetchLinks(setError);
	if (querySnapshot?.docs) {
		setLinks(querySnapshot.docs
			.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}))
		);
	}
}

export const handleImageUpload = async (image, setError) => {
	if (image) {
		return await uploadNewImage(image, setError);
	}
	return await uploadDefaultImage(setError);
};

export const updateCategoryIndices = async (categories, categoryId, categoryIdx, oldIdx, setError, isRemoved = false) => {
	try {
		const newIdx = Number(categoryIdx);
		const previousIdx = Number(oldIdx);
		let updatedCategories;

		if (isRemoved) {
			const removedCategory = categories.find(category => category.id === categoryId);
			if (!removedCategory) {
				setError(`Category with ID ${categoryId} not found.`);
				return categories;
			}

			updatedCategories = categories
				.filter(category => category.id !== categoryId)
				.map(category => ({
					...category,
					idx: Number(category.idx) > Number(removedCategory.idx)
						? Number(category.idx) - 1
						: Number(category.idx)
				}));
		} else {
			updatedCategories = categories.map(category => {
				const currentIdx = Number(category.idx);
				if (category.id === categoryId) {
					return {...category, idx: newIdx};
				} else if (previousIdx > newIdx && currentIdx >= newIdx && currentIdx < previousIdx) {
					return {...category, idx: currentIdx + 1};
				} else if (previousIdx < newIdx && currentIdx > previousIdx && currentIdx <= newIdx) {
					return {...category, idx: currentIdx - 1};
				}
				return {...category, idx: currentIdx};
			});
		}

		await Promise.all(updatedCategories.map(category =>
			updateCategory(category, setError)
		));

		return updatedCategories;
	} catch (error) {
		setError(`Error updating categories: ${error.message}`);
		return categories;
	}
};

export const updateLinksWhenCategoryChanged = async (links, oldCategory, newCategory, isMergeCategory, setError) => {
	try {
		const querySnapshot = await fetchLinksByCategory(oldCategory, setError);

		if (querySnapshot && querySnapshot.docs?.length > 0) {
			const updateLinksPromises = querySnapshot.docs.map(async (link) => {
				const linkId = link.id;
				const linkDB = link.data();
				const categoryName = getFormattedValue(newCategory);
				const linkName = generateUniqueLinkName(links, getFormattedValue(linkDB.name), categoryName, isMergeCategory, true);

				const linkData = {
					name: linkName,
					url: linkDB.url,
					image: linkDB.image,
					category: categoryName,
				};

				await updateLink(linkId, linkData, setError);

				return {...linkData, id: linkId};
			});

			return await Promise.all(updateLinksPromises);
		}

		return [];
	} catch (error) {
		setError(`Error while updating links by category: ${error.message}`);
		return [];
	}
};

export const isDuplicatedCategories = (categories, category) => {
	return categories.filter(cat => cat.category === category).length > 0;
}

export const removeCategoryIfLastLink = async (categories, categoryToCheck, setError) => {
	const categoryToRemove = categories.find(category => category.category === categoryToCheck);
	if (categoryToRemove) {
		const categoryId = categoryToRemove?.id ?? null;

		const categoryRemoved = await removeCategoryFromFirebase(categoryId, setError);
		if (categoryRemoved) {
			return await updateCategoryIndices(categories, categoryId, null, null, setError, true);
		}
	}
	return null;
};

export const verifyParamsUpdateLink = async (link, setError) => {
	if (!link?.id) {
		setError("Invalid link ID for update");
		return null;
	}

	const downloadUrl = await uploadImageToFirebase(link.image, setError);

	if (!downloadUrl || downloadUrl.empty) {
		setError("Download URL is empty");
		return null;
	}

	return downloadUrl;
};

export const verifyParamsRemoveCategory = (categoryData, setError) => {
	if (!categoryData?.id || !categoryData?.category) {
		setError("Invalid category data for removal");
		return null;
	}
	return categoryData?.id ?? null;
}

export const verifyParamsSaveCategory = (categoryData, categories, setError) => {
	if (!categoryData?.category || categoryData?.idx === undefined) {
		setError("Invalid category data for update");
		return null;
	}

	const category = categories.find(c => c.id === categoryData.id);
	return category?.id ?? null;
}
